import * as React from "react";
import HcpLayout from "../../../components/hcp/layout";
import { Seo } from "../../../components/seo";
import { Link } from "gatsby";
import useDeviceSize from "../../../hooks/use-device-size";
import ExternalLink from "../../../components/external-link";
import { useSiteMetadata } from "../../../hooks/use-site-metadata";
import { TfiAngleRight } from "react-icons/tfi";
import { Col, Row } from "react-grid-system";
import CustomVideo from "../../../components/cards/customVideo";
import videoThumbnail from "../../../images/support-organizations/finding-support-video-thumbnail.webp";
import SocialIconBlock from "../../../components/socialIconBlock";
import narcolepsyNetwork from "../../../images/support-organizations/nn-logo.webp";
import projectSleep from "../../../images/support-organizations/project-sleep-logo.webp";
import WUNLogo from "../../../images/support-organizations/wun-logo.webp";
import Day4NAP from "../../../images/support-organizations/day-four-naps-logo.webp";
import resourceImg from "../../../images/wakix-for-you/cta-resources.webp";
import resourceMobileImg from "../../../images/wakix-for-you/cta-resources-mobile.webp";
import personalImg from "../../../images/support-organizations/cta-personal-stories.webp";
import personalMobileImg from "../../../images/support-organizations/cta-personal-stories-mobile.webp";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import bannerImg from "../../../images/resources/hero-resources-overview.webp";
import bannerImgMobile from "../../../images/resources/hero-resources-overview-mobile.webp";
import { StaticImage } from "gatsby-plugin-image";
import "./support-organization.scss";
import InnerBanner from "../../../components/InnerBanner";

const SupportOrganizations = () => {
  const deviceSizes = useDeviceSize();
  const { externalLinkInfo } = useSiteMetadata();
  return (
    <HcpLayout pageClass="hcp-main support-organizations">
      <InnerBanner
        topHeading="Support Organizations"
        bannerClass="resources"
        bannerHeroImg={bannerImg}
        bannerHeroImgMobile={bannerImgMobile}
        bannerImgAlt="Chris, a real person with narcolepsy with cataplexy taking WAKIX, sitting with his phone"
        heroNameClass="on-right"
        heroNameImg={
          <StaticImage
            src="../../../images/resources/hero-resources-overview-name.webp"
            alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
            width={231}
            height={120}
            quality={95}
            layout="fixed"
            placeholder="none"
            style={{ transition: "opacity 0.5s" }}
            imgStyle={{ opacity: 1 }}
          />
        }
      />

      <section className="inner-first no-pb">
        <div className="centered-content">
          <div className="page-heading ph-btm">
            <h1>Narcolepsy Support Organizations</h1>
            <h2>Connect with others in the narcolepsy community</h2>
            <p>
              Support groups and organizations can provide information,
              connection, and more to people living with narcolepsy and their
              loved ones.
            </p>
          </div>
        </div>
      </section>

      <section className="card-logo__block">
        <div className="centered-content">
          <div className="card-logo">
            <div className="card-logo__media">
              <ExternalLink
                externalURL="https://narcolepsynetwork.org/"
                messageTitle={externalLinkInfo.TITLE}
                messageBody={externalLinkInfo.BODY}
                classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
              >
                <img src={narcolepsyNetwork} alt="Narcolepsy Network logo" />
              </ExternalLink>
            </div>
            <div className="card-logo__content">
              <div className="card-logo__title">
                <ExternalLink
                  externalURL="https://narcolepsynetwork.org/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  customTagTitle="Narcolepsy Network"
                >
                  <span className="btn-link asap asap-bold">
                    Narcolepsy Network
                  </span>
                </ExternalLink>
                <ul className="social-links">
                  <li>
                    <ExternalLink
                      externalURL="https://www.facebook.com/NarcolepsyNetwork"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Narcolepsy Network Facebook logo"
                    >
                      <FaFacebookF alt="Facebook icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://twitter.com/NarcolepsyNtwrk/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="X icon"
                    >
                      <TwitterXIcon />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.instagram.com/narcolepsynetwork/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Narcolepsy Network Instagram camera logo"
                    >
                      <FaInstagram alt="Instagram icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.tiktok.com/place/Narcolepsy-Network-22535796706151277"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Narcolepsy Network Tiktok logo"
                    >
                      <FaTiktok alt="Tik Tok icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.threads.net/@narcolepsynetwork?hl=en"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Wake Up Narcolepsy Threads icon"
                    >
                      <ThreadsIcon />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <p className="card-logo__desc">
                Founded in 1986, Narcolepsy Network is a US-based 501(c)(3),
                member-led community organization that works to educate,
                empower, and connect people impacted by narcolepsy.
              </p>
              <p className="card-logo__desc">
                Through the lens of advocacy, education, awareness, and support,
                Narcolepsy Network delivers programs and resources in the form
                of clinical updates and research, webinars, support groups, and
                advocacy opportunities. Narcolepsy Network also hosts an annual
                conference offering <nobr>up-to-date</nobr> information on
                clinical research, therapies, and lifestyle hacks, as well as
                networking and support.
              </p>
              <p className="card-logo__desc">
                {" "}
                <ExternalLink
                  externalURL="https://narcolepsynetwork.org/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Visit Narcolepsy Network’s website to learn more
                  </span>
                </ExternalLink>
                .
              </p>
              <p className="card-logo__desc">
                <ExternalLink
                  externalURL="https://nni.app.neoncrm.com/np/clients/nni/subscribe.jsp?subscription=15&"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Join Narcolepsy Network’s email list
                  </span>
                </ExternalLink>
                .
              </p>
              <p className="card-logo__desc">
                <ExternalLink
                  externalURL="https://narcolepsynetwork.org/virtual-support-group-meeting/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Register for one of Narcolepsy Network’s virtual support
                    groups
                  </span>
                </ExternalLink>
                .
              </p>
            </div>
          </div>
          <div className="card-logo">
            <div className="card-logo__media">
              <ExternalLink
                externalURL="https://project-sleep.com/"
                messageTitle={externalLinkInfo.TITLE}
                messageBody={externalLinkInfo.BODY}
                classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
              >
                <img src={projectSleep} alt="Project Sleep logo" />
              </ExternalLink>
            </div>
            <div className="card-logo__content">
              <div className="card-logo__title">
                <ExternalLink
                  externalURL="https://project-sleep.com/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link asap asap-bold">Project Sleep</span>
                </ExternalLink>

                <ul className="social-links">
                  <li>
                    <ExternalLink
                      externalURL="https://www.facebook.com/ProjectSleepAwareness/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Project Sleep Facebook logo"
                    >
                      <FaFacebookF alt="Facebook icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://twitter.com/project_sleep/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="X icon"
                    >
                      <TwitterXIcon />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.instagram.com/project_sleep/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Project Sleep Instagram camera logo"
                    >
                      <FaInstagram alt="Instagram icon" />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <p className="card-logo__desc">
                Project Sleep is an organization raising awareness about sleep
                health and sleep conditions, including programming to empower
                and support people with narcolepsy.
              </p>
              <p className="card-logo__desc">
                Project Sleep’s Rising Voices program helps foster a new
                generation of patient-advocates by encouraging people to share
                their personal stories to improve public understanding of sleep
                disorders.{" "}
                <ExternalLink
                  externalURL="https://project-sleep.com/rising-voices-of-narcolepsy/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">Discover how to get involved</span>
                </ExternalLink>
                .
              </p>

              <p className="card-logo__desc">
                World Narcolepsy Day (WND), occurring every year on September
                22, is dedicated to raising awareness of narcolepsy on a global
                scale, uniting the international narcolepsy community to inspire
                action, increase public knowledge, and elevate the voices of the
                3 million people living with narcolepsy worldwide.{" "}
                <ExternalLink
                  externalURL="https://project-sleep.com/worldnarcolepsyday/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">Learn more here</span>
                </ExternalLink>
                .
              </p>

              <p className="card-logo__desc">
                Project Sleep's Narcolepsy Nerd Alert is a series of live
                broadcasts, podcasts, and free toolkits (PDFs) addressing
                various aspects of living with narcolepsy, including school
                accommodations for narcolepsy and succeeding in the workplace
                with narcolepsy.{" "}
                <ExternalLink
                  externalURL="https://project-sleep.com/narcolepsy-nerd-alert/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Check out videos and toolkits from Narcolepsy Nerd Alert
                  </span>
                </ExternalLink>
                .
              </p>
              <p className="card-logo__desc">
                Project Sleep offers a Jack & Julie Narcolepsy Scholarship,
                which is a national scholarship program to support students with
                narcolepsy while also fostering awareness in high school and
                college settings.{" "}
                <ExternalLink
                  externalURL=""
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">Explore more here</span>
                </ExternalLink>
                .
              </p>
            </div>
          </div>
          <div className="card-logo">
            <div className="card-logo__media">
              <ExternalLink
                externalURL="https://www.wakeupnarcolepsy.org/"
                messageTitle={externalLinkInfo.TITLE}
                messageBody={externalLinkInfo.BODY}
                classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
              >
                <img src={WUNLogo} alt="Wake Up Narcolepsy logo" />
              </ExternalLink>
            </div>
            <div className="card-logo__content">
              <div className="card-logo__title">
                <ExternalLink
                  externalURL="https://www.wakeupnarcolepsy.org/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link asap asap-bold">
                    Wake Up Narcolepsy
                  </span>
                </ExternalLink>

                <ul className="social-links">
                  <li>
                    <ExternalLink
                      externalURL="https://www.facebook.com/wakeupnarcolepsy/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Wake Up Narcolepsy Facebook logo"
                    >
                      <FaFacebookF alt="Facebook icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://twitter.com/wakenarcolepsy/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="X icon"
                    >
                      <TwitterXIcon />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.instagram.com/wakeupnarcolepsy/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Wake Up Narcolepsy Instagram camera logo"
                    >
                      <FaInstagram alt="Instagram icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.tiktok.com/@wakeupnarcolepsy"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Wake Up Narcolepsy TikTok logo"
                    >
                      <FaTiktok alt="Tik Tok icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://www.threads.net/@wakeupnarcolepsy"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Wake Up Narcolepsy Threads icon"
                    >
                      <ThreadsIcon />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <p className="card-logo__desc">
                Wake Up Narcolepsy (WUN) is a 501(c)(3) nonprofit organization
                dedicated to driving narcolepsy awareness, education, support,
                and research towards improved treatments
              </p>
              <p className="card-logo__desc">
                WUN offers free, targeted online support groups to individuals
                with narcolepsy, their parents, and their families worldwide.
                Their programs are designed to bring together individuals who
                have been affected by the diagnosis of narcolepsy and provide
                the support and community necessary to thrive.{" "}
                <ExternalLink
                  externalURL="https://heypeers.com/organizations/2/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Find a weekly online support group
                  </span>
                </ExternalLink>
                .
              </p>

              <p className="card-logo__desc">
                There are several other resources and events supported by WUN,
                including education day videos and webinars, podcasts,
                fundraisers, and awareness campaigns. They host a camp-style
                family weekend for children and teens living with narcolepsy{" "}
                <ExternalLink
                  externalURL="https://www.wakeupnarcolepsy.org/community/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Check out all the supportive resources WUN offers
                  </span>
                </ExternalLink>
                .
              </p>
            </div>
          </div>
          <div className="card-logo align-items-center">
            <div className="card-logo__media">
              <ExternalLink
                externalURL="https://day4naps.org/"
                messageTitle={externalLinkInfo.TITLE}
                messageBody={externalLinkInfo.BODY}
                classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
              >
                <img
                  src={Day4NAP}
                  alt="A Day for Narcolepsy Awareness Projects logo"
                />
              </ExternalLink>
            </div>
            <div className="card-logo__content">
              <div className="card-logo__title">
                <ExternalLink
                  externalURL="https://day4naps.org/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link asap asap-bold">
                    A Day for Narcolepsy Awareness Projects (Day4NAPs)
                  </span>
                </ExternalLink>

                <ul className="social-links">
                  <li>
                    <ExternalLink
                      externalURL="https://www.facebook.com/Day4NAPS/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="Day4NAPs Facebook logo"
                    >
                      <FaFacebookF alt="Facebook icon" />
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      externalURL="https://twitter.com/day4naps/"
                      messageTitle={externalLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                      customTagTitle="X icon"
                    >
                      <TwitterXIcon />
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <p className="card-logo__desc">
                Day4NAPs is a platform with a mission to raise narcolepsy
                awareness by sharing stories of people living with narcolepsy
                and fundraising events happening worldwide.{" "}
                <ExternalLink
                  externalURL="https://day4naps.org/ideas/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                >
                  <span className="btn-link">
                    Get inspired and find ideas for your own narcolepsy
                    awareness events
                  </span>
                </ExternalLink>
                .
              </p>
            </div>
          </div>
          <div className="disclaimer-text text-center">
            <p className="asap asap-bold font-21">
              <strong>
                Harmony Biosciences is not responsible for the content of
                third-party websites.
              </strong>
            </p>
          </div>
        </div>
      </section>

      <section className="gray-bg video-section">
        <div className="centered-content">
          <Row className="row align-items-center">
            <Col lg={6} xl={7} className="two-column__video">
              <div className="col video-wrap">
                <div className="card-video-wrap fit">
                  <CustomVideo
                    videoId="1006597050"
                    videoIdParam="185e4200bc"
                    videoThumbnail={videoThumbnail}
                    altText={
                      "Finding support in the narcolepsy community video thumbnail"
                    }
                    customTagText="Finding support in the narcolepsy community video"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6} xl={5}>
              <div className="two-column__content">
                <h2>Finding support in the narcolepsy community</h2>
                <p>
                  Watch other people living with narcolepsy share their
                  experience with how they found support within the narcolepsy
                  community.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="two-col no-pb no-pt">
        <div className="col bg-img">
          <div className="two-col__inner bg-cover">
            {deviceSizes?.smUp ? (
              <img
                src={personalImg}
                className="two-col"
                alt="Chris, a real person with narcolepsy with cataplexy, sitting with friends outdoors"
              />
            ) : (
              <img
                src={personalMobileImg}
                className="two-col"
                alt="Chris, a real person with narcolepsy with cataplexy, sitting with friends outdoors"
              />
            )}
            <div className="two-col__content">
              <div className="two-col__title text-white has-textShadow">
                <h3>
                  Hear from real <br /> people taking{" "}
                  <span className="line-spacer">WAKIX</span>
                </h3>
              </div>
              <div className="btn-wrap m-content">
                <Link
                  to="/wakix-personal-stories"
                  className="btn btn-primary btn-primary--md"
                >
                  See their stories
                  <TfiAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col bg-img">
          <div className="two-col__inner inner bg-cover">
            {deviceSizes?.smUp ? (
              <img
                src={resourceImg}
                className="two-col"
                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
              />
            ) : (
              <img
                src={resourceMobileImg}
                className="two-col"
                alt="Kristen, a real person living with narcolepsy, sitting with her laptop"
              />
            )}
            <div className="two-col__content content ml-76">
              <div className="two-col__title text-white has-textShadow">
                <h3>
                  Access resources to start a conversation with your healthcare
                  provider
                </h3>
              </div>
              <div className="btn-wrap m-content">
                <Link
                  to="/resources-and-support/downloads"
                  className="btn btn-primary btn-primary--md"
                >
                  Go now
                  <TfiAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SocialIconBlock />
    </HcpLayout>
  );
};

export default SupportOrganizations;

export const Head = () => (
  <Seo
    title="Narcolepsy Support Organizations | WAKIX® (pitolisant)"
    description="Find narcolepsy support organizations and support groups to help people in the narcolepsy community navigate life."
    keywords="narcolepsy resources, narcolepsy patient advocacy, narcolepsy support groups, narcolepsy community"
  />
);

const TwitterXIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      alt="X icon"
    >
      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
    </svg>
  );
};

const ThreadsIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 448 512"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
      alt="Threads icon"
    >
      <path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"></path>
    </svg>
  );
};
